import React, { useState } from "react"
import { BiSearch } from "react-icons/bi"
import { Container, Box, HStack, InputGroup, InputLeftElement, InputRightElement, Input, Button, CloseButton } from "@chakra-ui/react"

type Props = {
  loading?: boolean
  handleGeolocation: () => void
  handleSearch: (query: string) => void
}

const StoreForm: React.FC<Props> = ({ loading, handleSearch, handleGeolocation }) => {
  const [query, setQuery] = useState("")

  const handleChange = e => {
    setQuery(e.target.value)
  }

  const handleClear = () => {
    setQuery("")
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleSearch(query)
  }
  return (
    <Container maxW="container.sm">
      <Box as="form" onSubmit={handleSubmit}>
        <HStack mb={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <BiSearch />
            </InputLeftElement>
            <Input mb={0} value={query} onChange={handleChange} placeholder="Enter a suburb or postcode" />
            <InputRightElement>
              <CloseButton onClick={handleClear} aria-label="Clear" title="Clear" />
            </InputRightElement>
          </InputGroup>
          <Button size="sm" height={10} onClick={handleSubmit} isDisabled={loading}>
            Search
          </Button>
        </HStack>
        <Button size="sm" variant="ghost" onClick={handleGeolocation} isDisabled={loading} mx={2}>
          Use my location
        </Button>
      </Box>
    </Container>
  )
}
export default StoreForm
