import React from "react"
import { Box, Heading, Text } from "@chakra-ui/react"
import Link from "@app/components/Link"

type Props = {
  store: any
  handleClick: (id: string) => void
}

const StoreCard: React.FC<Props> = ({ store, handleClick }) => {
  return (
    <Box onClick={() => handleClick(store.id)} mb={2} cursor="pointer" borderBottom="1px solid" borderColor="grey.300">
      <Box py="4" pb={6} align="left">
        <Heading as="h3" size="h3" fontWeight="600" letterSpacing={1} mb={2}>
          {store?.title}
        </Heading>
        {store?.address && <Text size="14">{store?.address}</Text>}
        {store?.suburb && <Text size="14">{store?.suburb}</Text>}
        {store?.state && (
          <Text size="14">
            {store?.state}, {store?.postcode}
          </Text>
        )}
        {store?.phone && (
          <Text color="grey.700" size="14" mt={4}>
            Tel: {store?.phone}
          </Text>
        )}
        <Text color="grey.700" as={Link} to={store.url} title={store?.title} fontSize={14}>
          View Store Details
        </Text>
        <Box pt={4}>
          {store?.openhours?.length > 0 &&
            store?.openhours.map((openhour, index) => (
              <Text size="14" key={index}>
                {openhour.day}: {openhour.hours}
              </Text>
            ))}
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(StoreCard)
