import React, { useState, useEffect } from "react"
import { Container, Box, Stack } from "@chakra-ui/react"
import { useConfigContext } from "@app/providers/config"
import Title from "@app/components/Sections/Title/Title"
import type { PageProps } from "@root/types/global"
import { useContent } from "@app/hooks/useContent"
import { useGeocode } from "@app/hooks/useGeocode"
import type { Props } from "@app/pages/store"
import Map from "@app/components/Map"
import StoreCard from "./StoreCard"
import StoreForm from "./StoreForm"

const INITIAL_LOCATION = {
  id: "",
  url: "",
  location: {
    lat: -37.9902239,
    lng: 145.0886447,
  },
  title: "",
  address: "",
  showInfoWindow: false,
}

const StoreList: React.FC<PageProps<Props>> = ({ page, stores }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { queryGeocode, getDistance } = useGeocode()

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [selectedStore, setSelectedStore] = useState(INITIAL_LOCATION)
  const [currentLocation, setCurrentLocation] = useState(INITIAL_LOCATION.location)
  const content = useContent(page)

  useEffect(() => {
    if (stores) {
      const sortedStores =
        stores.edges
          ?.map(({ node }: { node: any }, index: number) => ({
            ...node,
            id: String(index + 1),
            url: `${routes.STORE}/${node.handle.current}`,
            distance: getDistance(currentLocation, node.location) || -1,
          }))
          .sort((a: any, b: any) => a.distance - b.distance) || []
      setItems(sortedStores)
      setLoading(false)
    }
  }, [stores, currentLocation, selectedStore, getDistance, routes])

  const handleSearch = async (query: number) => {
    setLoading(true)
    const location = await queryGeocode(query)
    if (location?.lat && location?.lng) {
      setSelectedStore(INITIAL_LOCATION)
      setCurrentLocation(location)
    }
  }

  const handleGeolocation = () => {
    setLoading(true)
    navigator.geolocation.getCurrentPosition(position => {
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }

  const handleClick = (id: string) => {
    const store = items?.find(item => item.id === id)
    if (store) {
      setSelectedStore(store)
      setCurrentLocation(store.location)
    }
  }

  return (
    <>
      <Title title={page?.title} />
      <Container maxW="container.xl" pb={16} px={[4, 16]}>
        <Box as="section" pb={8} textAlign="center">
          <Box mb={8}>{content}</Box>
          <Box mb={16}>
            <StoreForm handleSearch={handleSearch} handleGeolocation={handleGeolocation} loading={loading} />
          </Box>
          <Box>
            <Stack direction={["column-reverse", "row"]} height="640px" alignItems="start">
              <Box w={["100%", "40%"]} maxH="100%" overflow="scroll" pr={[0, 8]} mt={[8, 0]}>
                {items && items?.map((store, index) => <StoreCard key={index} store={store} handleClick={handleClick} />)}
              </Box>
              <Box w="100%" h="100%" minH="300px">
                <Map data={items} currentLocation={currentLocation} selectedLocation={selectedStore || null} />
              </Box>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  )
}
export default StoreList
