import { useCallback } from "react"
import haversine from "haversine-distance"
import { useConfigContext } from "@app/providers/config"

export const useGeocode = () => {
  const {
    services: { googleMaps },
  } = useConfigContext()

  const queryGeocode = async (query: number | string) => {
    const queryString = isNaN(query) ? query : `Postcode+${query}`
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${queryString}+australia&key=${googleMaps.apiKey}`
    )
    const data = await response.json()
    return data?.status?.toLowerCase() === "ok" ? data.results?.[0]?.geometry?.location : {}
  }

  const getDistance = useCallback((from, to) => haversine(from, to) / 1000, [])

  return { queryGeocode, getDistance }
}
